<template>
  <div class="wrapper">
    <div class="header" v-on:click="rotate=false">
      <div class="logo"></div>
      <div class="showResult">
        <ul>
          <li>{{resultUp[0] ? resultUp[0].id : ''}}</li>
          <li>{{resultUp[1] ? resultUp[1].id : ''}}</li>
          <li>{{resultUp[2] ? resultUp[2].id : ''}}</li>
        </ul>
        || '
      </div>
      <div class="showInfo">
        <div class="basicInfo">
          <span class="nextIssue">下期：{{n+1}}</span>
          <span class="nextIssueTime">开奖：{{nextDate}}</span>
        </div>
        <div class="soundButton"></div>
      </div>
    </div>
    <div class="main">
      <div class="timeCountDown">
        <div class="countDownTime" v-show="!opening">{{this.countDowm}}</div>
        <div class="opening" v-show="opening">开奖中...</div>
      </div>

      <div class="numberWrapper">
        <div class="number">
          <div class="firstNumber">
            <div :class="{'rotate' : rotate}">{{resultDown[0] ? resultDown[0].id : ''}}</div>
          </div>
          <div class="secondNumber">
            <div :class="{'rotate' : rotate}">{{resultDown[1] ? resultDown[1].id : ''}}</div>
          </div>
          <div class="thirdNumber">
            <div :class="{'rotate' : rotate}">{{resultDown[2] ? resultDown[2].id : ''}}</div>
          </div>
        </div>
      </div>

      <div class="twinkle">
        <div class="firstTwinkle"></div>
        <div class="secondTwinkle"></div>
        <div class="thirdTwinkle"></div>
      </div>
      <div class="playButton" v-on:click="this.getRandomNumber"></div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'PCdandan',
    data() {
      return {
        obj: [
          {id: 0},
          {id: 1},
          {id: 2},
          {id: 3},
          {id: 4},
          {id: 5},
          {id: 6},
          {id: 7},
          {id: 8},
          {id: 9}
        ],
        resultUp: [
          {id: ''},
          {id: ''},
          {id: ''}
        ],
        resultDown: [
          {id: ''},
          {id: ''},
          {id: ''}
        ],
        rotate: false, // 控制旋转或者停止
        time: 15, // 初始倒计时，如果需要修改倒计时的数值，需要同时修改 getRandomNumber() 方法里面的 time
        n: 1, // 期数
        opening: false  // 文字“开奖中...”显示或者隐藏
      }
    },
    computed: {
      countDowm: function () {
        let h, m, s, restTime
        s = this.time
        if (s < 60) {
          h = 0
          m = 0
          s = s
        }
        if (s >= 60 && s <= 3600) {
          h = 0
          m = parseInt(s / 60)
          s = parseInt(s % 60)
        }

        if (s < 10) {
          s = '0' + s
        }
        if (m < 10) {
          m = '0' + m
        }
        if (h < 10) {
          h = '0' + h
        }

        return restTime = h + ':' + m + ':' + s
      },
      nextDate: function () {
        let now, show
        now = new Date()
        let h = now.getHours()
        h = h < 10 ? '0' + h : h
        let m = now.getMinutes()
        if (m < 10) {
          m = '0' + m
        } else if (m > 60) {
          m = '0' + m - 60
        } else {
          m = m
        }
        let s = now.getSeconds() + this.time
        if (s < 10) {
          s = '0' + s
        } else if (s > 60) {
          s = '0' + s - 60
          m + 1
        } else {
          s = s
        }
        show = h + ':' + m + ':' + s
        return show
      }
    },
    mounted() {
      setInterval(() => {
        this.time--
        this.getRandomNumber()
      }, 1000)

    },
    methods: {
      getRandomNumber: function () {
        if (this.time === 0) {
          this.rotate = true
          this.opening = true
          this.init()
        }
        if (this.time === -10) {  // 控制翻牌的时间长短，数值越小，翻牌时间越长
          this.resultUp = []
          this.resultDown = []
          this.opening = false
          for (let i = 0; i < 3; i++) {
            let item = []
            item = this.obj[Math.floor(Math.random() * 10)]
            this.resultUp.push(item)
            this.resultDown.push(item)
            this.rotate = false
            this.time = 15 // 如果修改倒计时时间，这里也需要修改，需要跟 time 数值一致
            this.n += 1
          }
        }

      },
      init: function () {
        this.resultDown = [
          {id: '', number: ''},
          {id: '', number: ''},
          {id: '', number: ''}
        ]
      }
    },
  }
</script>

<style src="./css/PCdandan.css" scoped>
</style>
